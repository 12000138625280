import React from 'react'

import Layout from 'components/layout'
import AboutUs from 'components/AboutUs'
import ContactButtonWithModal from 'components/AboutUs/ContactButtonWithModal'
import CubesPyramid from 'components/AboutUs/CubesPyramid'

import SEO from 'components/seo'
import aboutusSettings from 'site/aboutus/settings.json'

const AboutPage = ({ location }) => {
  return (
    <Layout
      location={location}
      title={aboutusSettings.title}
      subTitle={aboutusSettings.sub_title}
      ExtraUnderTitlesComponent={<ContactButtonWithModal />}
      ExtraRightComponent={<CubesPyramid />}>
      <SEO
        title={aboutusSettings.meta_title || aboutusSettings.title}
        description={aboutusSettings.meta_description || ''}
        location={location.href}
      />
      <AboutUs />
    </Layout>
  )
}

export default AboutPage
