import React from 'react'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import ClientsSwiper from 'components/ClientsSwiper'

import aboutusSettings from 'site/aboutus/settings.json'

const ClientsSwiperSection = () => {
  return (
    <SectionWithScrollBg className="aboutusClientsSwiper">
      <ClientsSwiper title={aboutusSettings.reviews_slider_title} />
    </SectionWithScrollBg>
  )
}

export default ClientsSwiperSection
