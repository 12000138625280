import React from 'react'
import * as styles from './NumbersList.module.scss'

import Item from './Item'

import aboutusSettings from 'site/aboutus/settings.json'

const NumbersList = () => {
  return (
    <ul className={styles.list}>
      {aboutusSettings.numbers_items.map((props, i) => (
        <Item {...props} key={`NumbersList-item-${i}`} />
      ))}
    </ul>
  )
}

export default NumbersList
