import React from 'react'

import * as styles from './FilesDownload.module.scss'

import Item from './Item'

import aboutusSettings from 'site/aboutus/settings.json'

const FilesDownload = () => {
  return (
    <ul className={styles.list}>
      {aboutusSettings.presentations_items.map(
        (props, i) =>
          props.is_show_on_website && (
            <Item
              {...props}
              key={`FilesDownload-presentations_items-item-${i}`}
            />
          ),
      )}
    </ul>
  )
}

export default FilesDownload
