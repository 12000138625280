import React from 'react'

import * as styles from './ValuesList.module.scss'

import Item from './Item'

import aboutusSettings from 'site/aboutus/settings.json'

const ValuesList = () => {
  return (
    <ul className={styles.list}>
      {aboutusSettings.values_items.map((props, i) => (
        <Item {...props} key={`ValuesList-item-${i}`} />
      ))}
    </ul>
  )
}

export default ValuesList
