import React, { useState } from 'react'
import * as styles from './DownloadForm.module.scss'

import { DefaultButton as Button, Input } from 'ui'
import Modal from 'components/Modal'

import useFeedbackMesage from 'contexts/FeedbackMesageContext'

import axios from 'axios'
import * as validator from 'validator'

import aboutusSettings from 'site/aboutus/settings.json'

const axiosStaticOptions = {
  url: 'https://api.emailjs.com/api/v1.0/email/send-form',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
}

const defaultValidator = {
  email: { value: false, message: '' },
}

const DownloadForm = ({ linkRef, isOpen, onClose }) => {
  const { setFeedbackMesage, openFeedbackMesage } = useFeedbackMesage()

  const [loading, setLoading] = useState(false)
  const [validatorState, setValidator] = useState(defaultValidator)

  const [formState, setformState] = useState({ email: null })

  const handleChangeForm = () => setValidator(defaultValidator)

  const setFormValue = (name, value) => {
    setformState(prev => ({ ...prev, [name]: value }))
  }

  const clear = name => {
    setValidator(c => ({ ...c, [name]: { value: false, message: '' } }))
  }

  const validate = () => {
    let isInValid = false
    if (!validator.isEmail(formState.email || '')) {
      setValidator(c => ({
        ...c,
        email: { value: true, message: 'Email is not valid' },
      }))
      isInValid = true
    } else {
      clear('email')
    }

    return !isInValid
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const isValid = validate()
    if (isValid) {
      setLoading(true)
      const formData = new FormData()
      Object.keys(formState).map(key => {
        formData.append(key, formState[key])
      })
      formData.append('form-name', 'hireus')
      formData.append('service_id', 'hello@axicube.io')
      formData.append('template_id', 'template_zm1524k')
      formData.append('user_id', 'user_VT1ITYE8nOGxGI4Z2aK6E')

      await axios({ ...axiosStaticOptions, data: formData })
        .then(() => {
          clear('email')
          setformState({
            email: null,
          })
          linkRef.current.click()
          setLoading(false)
          setFeedbackMesage(aboutusSettings.presentations_feedback_message)

          openFeedbackMesage()
          onClose()
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <Modal
      modalTitle={aboutusSettings.presentations_modal_title}
      modalDescription={aboutusSettings.presentations_modal_sub_title}
      isOpen={isOpen}
      onClose={onClose}>
      <form
        className={styles.form}
        encType="multipart/form-data"
        name="hireus"
        method="POST"
        onSubmit={handleSubmit}
        onChange={handleChangeForm}
        noValidate>
        <fieldset>
          <Input
            error={validatorState.email?.message}
            type="text"
            name="email"
            required
            placeholder="E-mail"
            setFormValue={setFormValue}
          />
        </fieldset>

        <Button
          disabled={loading}
          type="submit"
          title="Download"
          className={styles.form__button}
        />
      </form>
    </Modal>
  )
}

export default DownloadForm
