import React from 'react'

import * as styles from './Logos.module.scss'

import cn from 'classnames'

import { ImageFromPath } from 'utils'

import aboutusSettings from 'site/aboutus/settings.json'

const Logos = () => {
  return (
    <div className={styles.container}>
      {aboutusSettings.clients_images.map(({ image }, i) => (
        <div
          className={styles.container__item}
          key={`ClientsSection-Logos-image-${i}`}>
          <ImageFromPath url={image} />
        </div>
      ))}
    </div>
  )
}

export default Logos
