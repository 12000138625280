import React from 'react'

import OurNumbersSection from './OurNumbersSection'
import OurValuesSection from './OurValuesSection'
import OurTeamSection from './OurTeamSection'
import ClientsSection from './ClientsSection'
import ClientsSwiperSection from './ClientsSwiperSection'
import WhatsMoreSection from './WhatsMoreSection'
import Contact from 'components/Contact'

import aboutusSettings from 'site/aboutus/settings.json'

const AboutUs = () => {
  return (
    <>
      <OurNumbersSection />
      <OurValuesSection />
      <OurTeamSection />
      <ClientsSection />
      <ClientsSwiperSection />
      <WhatsMoreSection />
      <Contact
        title={aboutusSettings.contact_title}
        subTitle={aboutusSettings.contact_description}
      />
    </>
  )
}

export default AboutUs
