import React from 'react'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import { Title, SubTitle } from 'ui'
import FilesDownload from './FilesDownload'

import aboutusSettings from 'site/aboutus/settings.json'

const WhatsMoreSection = () => {
  return (
    <SectionWithScrollBg className="aboutusWhatsMore">
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={aboutusSettings.presentations_title} />
          {aboutusSettings.presentations_sub_title && (
            <SubTitle text={aboutusSettings.presentations_sub_title} />
          )}
        </div>
      </div>
      <FilesDownload />
    </SectionWithScrollBg>
  )
}

export default WhatsMoreSection
