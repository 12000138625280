import React from 'react'

import * as styles from './OurNumbersSection.module.scss'

import cn from 'classnames'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import { Title, SubTitle } from 'ui'
import NumbersList from './NumbersList'

import aboutusSettings from 'site/aboutus/settings.json'

const OurNumbersSection = () => {
  return (
    <SectionWithScrollBg className={cn('aboutusNumbers', styles.marginTop)}>
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={aboutusSettings.numbers_title} className="text-white" />
          {aboutusSettings.numbers_sub_title && (
            <SubTitle
              text={aboutusSettings.numbers_sub_title}
              className="text-white"
            />
          )}
        </div>
      </div>
      <NumbersList />
    </SectionWithScrollBg>
  )
}

export default OurNumbersSection
