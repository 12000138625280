import React from 'react'

import * as styles from './OurTeamList.module.scss'

import Item from './Item'

import aboutusSettings from 'site/aboutus/settings.json'

const OurTeamList = () => {
  return (
    <ul className={styles.list}>
      {aboutusSettings.team_items.map((props, i) => (
        <Item {...props} key={`OurTeamList-item-${i}`} />
      ))}
    </ul>
  )
}

export default OurTeamList
