import React from 'react'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import { Title, SubTitle } from 'ui'
import ValuesList from './ValuesList'

import aboutusSettings from 'site/aboutus/settings.json'

const OurValuesSection = () => {
  return (
    <SectionWithScrollBg className="aboutusValues">
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={aboutusSettings.values_title} />
          {aboutusSettings.values_sub_title && (
            <SubTitle text={aboutusSettings.values_sub_title} />
          )}
        </div>
      </div>
      <ValuesList />
    </SectionWithScrollBg>
  )
}

export default OurValuesSection
