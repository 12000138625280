import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as styles from './DownloadButton.module.scss'

import { DefaultButton as Button } from 'ui'
import DownloadForm from './DownloadForm'

const DownloadButton = ({ filePath, className }) => {
  const linkRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `,
  )

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const getImageNameFromSrc = src => {
    return src?.split('/').pop().split('.')[0]
  }

  const item = allFile.edges.find(
    item =>
      getImageNameFromSrc(item.node.publicURL) ===
      getImageNameFromSrc(filePath),
  )

  const publicURL = item?.node?.publicURL
  const fileName = item?.node?.name

  return (
    <>
      <Button
        type="button"
        title="Download"
        variant="secondary"
        onClick={handleOpen}
        className={className}
      />

      <DownloadForm linkRef={linkRef} isOpen={isOpen} onClose={handleClose} />

      <a
        ref={linkRef}
        href={publicURL}
        download={fileName}
        className={styles.hidden}>
        Download
      </a>
    </>
  )
}

export default DownloadButton
