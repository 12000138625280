import React from 'react'

import * as styles from './Item.module.scss'

import { MainTitle, SubTitle } from 'ui'

const Item = ({ title, text }) => {
  return (
    <li className={styles.item}>
      <MainTitle text={title} className="text-white" as="h2" />
      <SubTitle text={text} className="grey-text" />
    </li>
  )
}

export default Item
