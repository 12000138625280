import React from 'react'

import * as styles from './OurTeamSection.module.scss'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import { Title, SubTitle } from 'ui'
import OurTeamList from './OurTeamList'

import aboutusSettings from 'site/aboutus/settings.json'

const OurTeamSection = () => {
  return (
    <SectionWithScrollBg className="aboutusTeam">
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={aboutusSettings.team_title} />
          {aboutusSettings.team_sub_title && (
            <SubTitle text={aboutusSettings.team_sub_title} />
          )}
        </div>
      </div>
      <div className="row">
        <OurTeamList />
      </div>
    </SectionWithScrollBg>
  )
}

export default OurTeamSection
