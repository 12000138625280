import React from 'react'

import * as styles from './Item.module.scss'

import { Title, SubTitle } from 'ui'
import { ImageFromPath } from 'utils'

const Item = ({ title, text, image }) => {
  return (
    <li className={styles.item}>
      <ImageFromPath url={image} />
      <Title text={title} variant="small" className={styles.item__title} />
      <SubTitle text={text} variant="small" className="black-light-text" />
    </li>
  )
}

export default Item
