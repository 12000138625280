import React, { useState } from 'react'

import * as styles from './ContactButtonWithModal.module.scss'

import { DefaultButton as Button } from 'ui'

import Form from 'components/FrontPage/FeedbackSection/Form/Form'
import Modal from 'components/Modal'

const ContactButtonWithModal = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        title="Let’s Talk"
        type="button"
        className={styles.button}
        onClick={handleOpen}
      />
      <Modal isOpen={open} onClose={handleClose}>
        <Form isModal afterSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default ContactButtonWithModal
