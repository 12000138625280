import React from 'react'

import * as styles from './Item.module.scss'

import DownloadButton from './DownloadButton'
import { ImageFromPath } from 'utils'

const Item = ({ name, preview_image, file }) => {
  return (
    <li className={styles.item}>
      <div className={styles.item__container}>
        <ImageFromPath
          url={preview_image}
          className={styles.item__container__image}
        />
        <h2 className={styles.item__container__title}>{name}</h2>
      </div>
      <div className={styles.item__container}>
        <DownloadButton
          filePath={file}
          className={styles.item__container__button}
        />
      </div>
    </li>
  )
}

export default Item
