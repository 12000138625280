import React from 'react'

import * as styles from './CubesPyramid.module.scss'

import cn from 'classnames'

import aboutusSettings from 'site/aboutus/settings.json'

const CubesPyramid = () => {
  return (
    <div className="col-xxl-4 col-lg-4 col-md-12 position-relative">
      <div className={styles.wrapper}>
        <div className={styles.translateContainer}>
          <div
            className={cn(styles.cube, styles.animated)}
            style={{
              '--speed': aboutusSettings.cube_animation_speed
                ? `${aboutusSettings.cube_animation_speed}s`
                : '1s',
            }}
          />
        </div>
        <div className={styles.wrapper__container}>
          <div className={styles.cube} />
        </div>
        <div className={styles.wrapper__container}>
          <div className={styles.cube} />
          <div className={styles.cube} />
        </div>
        <div className={styles.wrapper__container}>
          <div className={styles.cube} />
          <div className={styles.cube} />
          <div className={styles.cube} />
        </div>
      </div>
    </div>
  )
}

export default CubesPyramid
