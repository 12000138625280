import React from 'react'

import * as styles from './Item.module.scss'

import { SubTitle } from 'ui'
import { ImageFromPath } from 'utils'

const Item = ({ title, image, tooltip }) => {
  return (
    <li className={styles.item}>
      <div className={styles.item__imageWrap}>
        <ImageFromPath url={image} />
        <div className={styles.item__imageWrap__tooltip}>
          <SubTitle
            text={tooltip}
            variant="small"
            className={styles.item__imageWrap__tooltip__text}
          />
        </div>
      </div>

      <SubTitle text={title} as="h2" className={styles.item__subTitle} />
    </li>
  )
}

export default Item
