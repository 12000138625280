import React from 'react'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import { Title, SubTitle } from 'ui'
import Logos from './Logos'

import aboutusSettings from 'site/aboutus/settings.json'

const ClientsSection = () => {
  return (
    <SectionWithScrollBg className="aboutusClients">
      <div className="row">
        <div className="col-xxl-6 col-lg-6 col-md-12 d-flex flex-column justify-content-center">
          <div className="col-xxl-10 col-lg-11 col-md-12">
            <Title
              text={aboutusSettings.clients_title}
              className="text-white"
            />
            {aboutusSettings.clients_sub_title && (
              <SubTitle
                text={aboutusSettings.clients_sub_title}
                className="grey-text"
              />
            )}
          </div>
        </div>
        <div className="col-xxl-6 col-lg-6 col-md-12">
          <Logos />
        </div>
      </div>
    </SectionWithScrollBg>
  )
}

export default ClientsSection
